import { call, put, takeEvery } from "redux-saga/effects";

import { getReportQualityFail, getReportQualitySuccess } from "./slice";
import { getReportQuality } from "../../api/backend_helper";

function* getReportQualitySaga(action) {
    try {
        const { buildingId } = action.payload;
        const data = yield call(getReportQuality, { buildingId });

        yield put(getReportQualitySuccess(data));
    } catch (error) {
        yield put(getReportQualityFail(error.message));
    }
}

function* ReportQualitySaga() {
    yield takeEvery("reportQuality/getReportQuality", getReportQualitySaga);
}

export default ReportQualitySaga;
