import React, { useState, useEffect, useRef, useContext } from "react";
import HeaderReport from "../../layout/HeaderReport/HeaderReport";
import { TabMenu } from "primereact/tabmenu";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { statusTemplate } from "../../components/utils";
import FormatDate from "../Report/FormatDate";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    getDashboardQuantity,
    getAllDashboardQuantity,
} from "../../store/dashboardQuantity/slice";
import {
    downloadExcelQuantity,
    setExcelDataQuantityNull,
} from "../../store/dashboardQuantity/slice";
import { site } from "../../api/url_helper";
import LoadingSpinner from "../Report/LoadingSpinner";
import { exportQuantityPDF } from "./ExportQuantityPDF";
import { imageTemplate } from "../Report/ProductInprogress";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { DataContext } from "../../contexts/data/DataProvider";
import { FilterMatchMode } from "primereact/api";
import Intersection from "../Plan/intersection";
import { Paginator } from "primereact/paginator";
import { formatDateApi } from "../../feature/formatDateApi";
import { statusFilterTemplate } from "../../constants/status";
import ModalFilterCategoryReportQuantity from "./ModalFilterCategoryQuantity";
import QR from "../Plan/QR";
import CustomMultiSelect from "../../constants/CustomMultiSelect";
import { resetPaginationQuantity } from "../../store/dashboardQuantity/slice";
import { useLocation } from "react-router-dom";
import axios from "axios";

export default function ReportQuantity() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { nameUnit, selectedTeam } = useContext(DataContext);

    const [tabMenuActive, setTabmenuActive] = useState(0);
    const [dataTable, setDataTable] = useState([]);
    const toast = useRef(null);
    const downloadLink = useRef(null);
    const [building, setBuilding] = useState(null);
    const [orderCode, setOrderCode] = useState("");
    const [category, setCategory] = useState("");
    const [factoryCode, setFactoryCode] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
    const [nameBuilding, setNameBuilding] = useState("");
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectFactoryCode, setSelectFactoryCode] = useState(null);
    const [qrDetail, setQrDetail] = useState(null);
    const [visible3, setVisible3] = useState(false);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [allData, setAllData] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const token = localStorage.getItem("accessToken");
    useEffect(() => {
        dispatch(resetPaginationQuantity());
    }, [location.pathname, dispatch]);

    const {
        buildingData,
        exportExcelQuantity,
        exportExcelQuantityError,
        currentPage,
        allDataQuantity,
    } = useSelector(
        (state) => ({
            exportExcelQuantityError: state.DashboardQuantity.errorExcelData,
            exportExcelQuantity: state.DashboardQuantity.excelData,
            buildingData: state.Production.dataBuilding,
            currentPage: state.DashboardQuantity.currentPage,
            allDataQuantity: state.DashboardQuantity.allData,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (
            building ||
            orderCode ||
            category ||
            factoryCode ||
            startDate ||
            endDate ||
            selectedStatuses
        ) {
            setAllData([]);
        }
    }, [
        building,
        orderCode,
        category,
        factoryCode,
        startDate,
        endDate,
        selectedStatuses,
    ]);

    useEffect(() => {
        if (allDataQuantity?.data?.plans?.items) {
            setAllData((prevData) => {
                const existingIds = new Set(prevData.map((item) => item.id));

                const newItems = allDataQuantity.data.plans.items.filter(
                    (item) => !existingIds.has(item.id)
                );

                return [...prevData, ...newItems];
            });
        }
    }, [
        allDataQuantity,
        currentPage,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        selectedStatuses,
    ]);

    useEffect(() => {
        const isLoaded =
            allData.length === allDataQuantity?.data?.plans?.totalItems;

        if (isLoaded) {
            setIsDataLoaded(true);
        } else {
            setIsDataLoaded(false);
        }
    }, [allDataQuantity, allData]);

    const handleStatusChange = (newStatuses) => {
        setSelectedStatuses(newStatuses);
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "Building.buildingCode": {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
        },
        orderCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
        productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        intendFinishDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        status: { value: [], matchMode: FilterMatchMode.IN },
    });

    const { buildingName } = useContext(DataContext);
    useEffect(() => {
        if (buildingName) setNameBuilding(buildingName);
    }, [buildingName]);

    const handleTeamProgress = (rowData) => {
        setDialogLocationVisible(true);
        setSelectedRow(rowData);
        setSelectFactoryCode(rowData.factoryCode);
    };

    //format số lượng
    function formatQuantity(quantity) {
        if (quantity !== undefined && quantity !== null) {
            let formatted = quantity.toString();

            formatted = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formatted;
        } else {
            return 0;
        }
    }

    const DashboardQuantityData = useSelector(
        (state) => state.DashboardQuantity.dashboardQuantityData,
        shallowEqual
    );

    useEffect(() => {
        let status = "Tất cả";
        if (DashboardQuantityData) {
            status =
                DashboardQuantityData.data.listStatus[tabMenuActive]?.label;
        }
        const timeout = setTimeout(() => {
            if (startDate && endDate) {
                dispatch(
                    getDashboardQuantity({
                        page: (first + rows) / rows,
                        limit: rows,
                        timeStartReq: startDate,
                        timeEndReq: endDate,
                        status,
                        filterBuildings: JSON.stringify(building),
                        orderCode,
                        category,
                        factoryCode,
                        filterStatus: JSON.stringify(selectedStatuses),
                        filterUnit: JSON.stringify(nameUnit),
                        filterTeamWorking: JSON.stringify(selectedTeam),
                    })
                );
            }
        }, 400);
        return () => clearTimeout(timeout);
    }, [
        dispatch,
        building,
        orderCode,
        category,
        factoryCode,
        startDate,
        endDate,
        tabMenuActive,
        first,
        rows,
        selectedStatuses,
        nameUnit,
        selectedTeam,
    ]);

    useEffect(() => {
        const updateDataTable = () => {
            if (DashboardQuantityData) {
                setDataTable(DashboardQuantityData?.data?.plans?.items);
            }
        };
        updateDataTable();
    }, [DashboardQuantityData]);

    useEffect(() => {
        const updateDataTable = () => {
            const status =
                DashboardQuantityData?.data?.listStatus[tabMenuActive]?.label;
            const filteredData =
                status === "Tất cả"
                    ? DashboardQuantityData?.data?.plans?.items
                    : DashboardQuantityData?.data?.plans?.items.filter(
                          (item) => item.status === status
                      );
            setDataTable(filteredData);
        };
        updateDataTable();
    }, [tabMenuActive]);

    //export excel
    const exportExcelHandle = () => {
        const id = buildingData?.data?.items.find(
            (item) => item.buildingCode === building
        )?.id;

        if (building) {
            dispatch(
                downloadExcelQuantity({
                    type: "quantity-statistics",
                    building_id: building,
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        } else {
            dispatch(
                downloadExcelQuantity({
                    type: "quantity-statistics",
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        }
    };

    useEffect(() => {
        if (exportExcelQuantity) {
            const url = `${site}/${exportExcelQuantity.file}`;
            toast.current.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xuất excel thành công.",
                life: 3000,
            });
            dispatch(setExcelDataQuantityNull());

            // Tự động tải file
            downloadLink.current.href = url;
            downloadLink.current.download = "exported_data.xlsx";
            downloadLink.current.click();
        }
    }, [dispatch, exportExcelQuantity]);

    useEffect(() => {
        if (exportExcelQuantityError) {
            toast.current.show({
                severity: "error",
                summary: "Thất bại",
                detail: exportExcelQuantityError.response.data.message,
                life: 3000,
            });
            dispatch(setExcelDataQuantityNull());
        }
    }, [dispatch, exportExcelQuantityError]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const handleDetailScanned = (detail) => {
        setQrDetail(detail);
    };

    const handleSelectionChange = (e) => {
        const selected = e.value || [];
        const currentPageItems = dataTable || [];

        // Loại bỏ các phần tử thuộc trang hiện tại khỏi selectedProducts
        const filteredProducts =
            selectedProducts?.filter(
                (item) => !currentPageItems.some((row) => row.id === item.id)
            ) || [];

        // Gộp các phần tử không bị xóa với phần tử vừa chọn
        const updatedSelection = [...filteredProducts, ...selected];

        // Loại bỏ phần tử trùng lặp
        const uniqueSelection = Array.from(
            new Map(updatedSelection.map((item) => [item.id, item])).values()
        );

        setSelectedProducts(uniqueSelection);
    };

    // const handleExportClick = (type) => {
    //     if (!isDataLoaded) {
    //         toast.current.show({
    //             severity: "warn",
    //             summary: "Cảnh báo",
    //             detail: "Vui lòng đợi dữ liệu được tải đầy đủ.",
    //             life: 3000,
    //         });
    //         return;
    //     }
    //     exportQuantityPDF(
    //         type,
    //         FormatDate(startDate),
    //         FormatDate(endDate),
    //         allData,
    //         DashboardQuantityData?.data?.totals,
    //         nameBuilding
    //     );
    // };
    const handleExportClick = async (type) => {
        if (startDate && endDate) {
            try {
                // Fetch the first page's data to get the total pages for pagination
                const response = await axios.get(
                    `${site}/api/dashboard/plan-statistics`,
                    {
                        params: {
                            page: page,
                            limit: 100,
                            timeStartReq: startDate,
                            timeEndReq: endDate,
                            filterBuildings: JSON.stringify(building),
                            orderCode,
                            category,
                            factoryCode,
                            filterStatus: JSON.stringify(selectedStatuses),
                            filterUnit: JSON.stringify(nameUnit),
                            filterTeamWorking: JSON.stringify(selectedTeam),
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json", // Fix header key
                        },
                        withCredentials: true,
                    }
                );

                const totalPages = response?.data?.data?.result?.totalPages;

                const allDataArray = [];
                const requests = [];

                // Fetch data for all pages concurrently
                for (let page = 1; page <= totalPages; page++) {
                    requests.push(
                        axios.get(`${site}/api/dashboard/plan-statistics`, {
                            params: {
                                page: page,
                                limit: 100,
                                timeStartReq: startDate,
                                timeEndReq: endDate,
                                filterBuildings: JSON.stringify(building),
                                orderCode,
                                category,
                                factoryCode,
                                filterStatus: JSON.stringify(selectedStatuses),
                                filterUnit: JSON.stringify(nameUnit),
                                filterTeamWorking: JSON.stringify(selectedTeam),
                            },
                            headers: {
                                Authorization: `Bearer ${token}`,
                                "Content-Type": "application/json", // Fix header key
                            },
                            withCredentials: true,
                        })
                    );
                }

                const responses = await Promise.all(requests);

                responses.forEach((res) => {
                    allDataArray.push(...res.data?.data?.result.items);
                });

                // Trigger the export logic
                exportQuantityPDF(
                    type,
                    FormatDate(startDate),
                    FormatDate(endDate),
                    allDataArray,
                    DashboardQuantityData?.data?.totals,
                    nameBuilding
                );
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.current.show({
                    severity: "error",
                    summary: "Lỗi",
                    detail: "Có lỗi xảy ra khi tải dữ liệu.",
                    life: 3000,
                });
            }
        } else {
            console.log("Start Date or End Date is missing");
        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <a ref={downloadLink} style={{ display: "none" }} />
            <HeaderReport
                building={building}
                orderCode={orderCode}
                category={category}
                factoryCode={factoryCode}
                setBuilding={setBuilding}
                setOrderCode={setOrderCode}
                setCategory={setCategory}
                setFactoryCode={setFactoryCode}
                setStartDateOutput={setStartDate}
                setEndDateOutput={setEndDate}
                exportClick={(e) => handleExportClick("save")}
                printReport={(e) => handleExportClick("print")}
                type="quantity"
                exportExcel={exportExcelHandle}
            />
            <div className="bg-white border-round-md shadow-1 px-3 mt-3">
                <div className="flex md:justify-content-between md:align-items-center flex-column md:flex-row gap-4">
                    <TabMenu
                        model={DashboardQuantityData?.data?.listStatus.map(
                            (item, index) => ({
                                ...item,
                                template: () =>
                                    tabMenuTemplate(
                                        item,
                                        index,
                                        tabMenuActive,
                                        setTabmenuActive
                                    ),
                            })
                        )}
                        activeIndex={tabMenuActive}
                        onTabChange={(e) => setTabmenuActive(e.index)}
                        pt={{ menuitem: "p-0" }}
                        style={{ maxWidth: "70%" }}
                    />
                    <div className="flex gap-4">
                        <CustomMultiSelect
                            value={selectedStatuses}
                            onChange={handleStatusChange}
                            className="max-w-14rem"
                        />
                        <Button
                            label="Lọc theo hạng mục"
                            onClick={() => setOpenModal(true)}
                        ></Button>
                    </div>
                </div>
                {DashboardQuantityData && DashboardQuantityData.data ? (
                    <div>
                        <DataTable
                            value={dataTable}
                            rowClassName="custom-row-class"
                            size="small"
                            emptyMessage="Không có dữ liệu"
                            filters={filters}
                            onFilter={(e) => setFilters(e.filters)}
                            scrollable
                            scrollHeight="65vh"
                            selectionMode="checkbox"
                            selection={selectedProducts || []}
                            onSelectionChange={handleSelectionChange}
                            dataKey="id"
                        >
                            <Column selectionMode="multiple"></Column>
                            <Column
                                header="STT"
                                body={(rowData, options) => (
                                    <div className="text-center">
                                        {first + options.rowIndex + 1}
                                    </div>
                                )}
                                style={{ width: "3%" }}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                            <Column
                                header="Tên công trình"
                                field="Building.name"
                                style={{ minWidth: "150px" }}
                                filter
                            ></Column>
                            <Column
                                header="Mã ĐH"
                                style={{ minWidth: "100px" }}
                                field="orderCode"
                                filter
                            ></Column>
                            <Column
                                header="Mã nhà máy"
                                field="factoryCode"
                                style={{ minWidth: "130px" }}
                                filter
                            ></Column>
                            <Column
                                header="Hạng mục"
                                style={{
                                    lineHeight: "1.5",
                                    minWidth: "200px",
                                }}
                                field="categoryName"
                                filter
                            ></Column>
                            <Column
                                header="Hình ảnh"
                                style={{ minWidth: "100px" }}
                                body={imageTemplate}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                            <Column
                                header="SL"
                                field="quantity"
                                style={{ minWidth: "70px" }}
                            ></Column>
                            <Column
                                header="ĐVT"
                                field="unit"
                                style={{ minWidth: "50px" }}
                                filter
                            ></Column>
                            <Column
                                header="Mã sản phẩm"
                                field="productCode"
                                style={{ minWidth: "130px" }}
                                filter
                            ></Column>
                            <Column
                                header="Kế hoạch hoàn thành"
                                field="intendFinishDate"
                                style={{ minWidth: "120px" }}
                                body={(rowData) => (
                                    <div
                                        style={{
                                            backgroundColor: `${rowData.color}`,
                                            padding: "4px 0",
                                            margin: "0 26px",
                                            borderRadius: "5px",
                                            color: `${
                                                rowData.color
                                                    ? "white"
                                                    : "black"
                                            }`,
                                        }}
                                    >
                                        {FormatDate(rowData.intendFinishDate)}
                                    </div>
                                )}
                                sortable
                                align="center"
                                alignHeader="center"
                            ></Column>
                            <Column
                                header="Trạng thái"
                                field="status"
                                body={statusTemplate}
                                alignHeader={"center"}
                                align={"center"}
                                style={{ minWidth: "100px" }}
                            ></Column>
                            <Column
                                header="Định vị"
                                body={(rowData) => (
                                    <div className="text-center">
                                        <Button
                                            onClick={() =>
                                                handleTeamProgress(rowData)
                                            }
                                            icon="pi pi-map-marker"
                                            rounded
                                            text
                                            severity="secondary"
                                            aria-label="Bookmark"
                                            style={{ color: "black" }}
                                        />
                                        <p>
                                            {rowData.status !==
                                                "Hoàn thành đúng tiến độ" &&
                                            rowData.status !==
                                                "Hoàn thành trễ tiến độ"
                                                ? rowData.teamWorking
                                                : "Đã giao"}
                                        </p>
                                    </div>
                                )}
                                alignHeader={"center"}
                                align={"center"}
                                style={{ minWidth: "100px" }}
                            ></Column>
                            <Column
                                header="Số lượng kế hoạch"
                                field="totalProductionPlan"
                                body={(rowData) => (
                                    <div>
                                        {formatQuantity(
                                            rowData.totalProductionPlan
                                        )}
                                    </div>
                                )}
                                style={{ minWidth: "120px" }}
                                alignHeader={"right"}
                                align={"right"}
                                footer={
                                    <div>
                                        {formatQuantity(
                                            DashboardQuantityData?.data?.totals
                                                ?.totalProductionPlan
                                        )}
                                    </div>
                                }
                            ></Column>

                            <Column
                                header="Sản lượng kế hoạch"
                                alignHeader={"right"}
                                style={{ minWidth: "200px" }}
                                align={"right"}
                                body={(rowData) => (
                                    <div>
                                        {(
                                            (rowData?.totalProductionPlan ||
                                                0) * (rowData?.price || 0)
                                        )?.toLocaleString("vi-VN")}
                                    </div>
                                )}
                                footer={
                                    <div>
                                        {DashboardQuantityData?.data?.totals?.totalProductionPlanPrice?.toLocaleString(
                                            "vi-VN"
                                        )}
                                    </div>
                                }
                            ></Column>
                            <Column
                                header="Chưa thực hiện"
                                field="notImplement"
                                body={(rowData) => (
                                    <div>
                                        {(
                                            (rowData?.TempProduct
                                                ?.notImplemented || 0) *
                                            (rowData?.price || 0)
                                        )?.toLocaleString("vi-VN")}
                                    </div>
                                )}
                                alignHeader={"right"}
                                align={"right"}
                                style={{ minWidth: "200px" }}
                                footer={
                                    <div>
                                        {DashboardQuantityData?.data?.totals?.totalNotImplementedPrice?.toLocaleString(
                                            "vi-VN"
                                        )}
                                    </div>
                                }
                            ></Column>
                            <Column
                                header="Đang thực hiện"
                                field="TempProduct.working"
                                body={(rowData) => (
                                    <div>
                                        {(
                                            (rowData?.TempProduct?.working ||
                                                0) * (rowData?.price || 0)
                                        )?.toLocaleString("vi-VN")}
                                    </div>
                                )}
                                alignHeader={"right"}
                                align={"right"}
                                style={{ minWidth: "200px" }}
                                footer={
                                    <div>
                                        {DashboardQuantityData?.data?.totals?.totalWorkingPrice?.toLocaleString(
                                            "vi-VN"
                                        )}
                                    </div>
                                }
                            ></Column>
                            <Column
                                header="Hoàn thành"
                                field="TempProduct.complete"
                                body={(rowData) => (
                                    <div style={{ paddingRight: "10px" }}>
                                        {(
                                            (rowData?.TempProduct?.complete ||
                                                0) * (rowData?.price || 0)
                                        )?.toLocaleString("vi-VN")}
                                    </div>
                                )}
                                alignHeader={"right"}
                                align={"right"}
                                style={{
                                    minWidth: "200px",
                                    paddingRight: "10px",
                                }}
                                footer={
                                    <div>
                                        {DashboardQuantityData?.data?.totals?.totalCompletePrice?.toLocaleString(
                                            "vi-VN"
                                        )}
                                    </div>
                                }
                            ></Column>
                            <Column
                                header="In QR"
                                body={(rowData) => (
                                    <div className="text-center">
                                        <Button
                                            onClick={() => {
                                                setQrDetail(rowData);
                                                setVisible3(true);
                                            }}
                                            icon="pi pi-qrcode"
                                            rounded
                                            text
                                            severity="secondary"
                                            aria-label="QR"
                                            style={{ color: "black" }}
                                        />
                                    </div>
                                )}
                                style={{ width: "8%", minWidth: "100px" }}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                        </DataTable>
                        <div className="card">
                            <Paginator
                                first={first}
                                rows={rows}
                                totalRecords={
                                    DashboardQuantityData?.data?.plans
                                        ?.totalItems
                                }
                                rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                                onPageChange={onPageChange}
                            />
                        </div>
                        <Intersection
                            visible={dialogLocationVisible}
                            onHide={() => setDialogLocationVisible(false)}
                            factoryCode={selectFactoryCode}
                            rowData={selectedRow}
                        />
                        <ModalFilterCategoryReportQuantity
                            visible={openModal}
                            onHide={() => setOpenModal(false)}
                            data={selectedProducts}
                        />
                        <QR
                            isShow={visible3}
                            hiden={() => setVisible3(false)}
                            detail={qrDetail}
                            onDetailScanned={handleDetailScanned}
                        />
                    </div>
                ) : (
                    <LoadingSpinner />
                )}
            </div>
        </div>
    );
}
