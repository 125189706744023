import React, { useEffect, useState, useContext, useRef } from "react";
import HeaderReport from "../../layout/HeaderReport/HeaderReport";
import HeaderReportFactory from "./HeaderReportFactory";
import DonutChart from "./DonutChart";
import ProductInprogress from "./ProductInprogress";
import ProductInlate from "./ProductInlate";
import DonutChartPDF from "./DonutChartPDF";
import HeaderReportFactoryPDF from "./HeaderReportFactoryPDF";
import {
    getDashboardFactory,
    getProductInlateFactory,
    getProductInprogressFactory,
    setExcelDataBuildingNull,
    resetPagination,
} from "../../store/dashboard/slice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "../../assets/scss/theme-base/dashboard.css";
import FormatDate from "./FormatDate";
import { exportFactoryPDF } from "./ExportFactoryPDF";
import "./ReportFactory.scss";
import { DataContext } from "../../contexts/data/DataProvider";
import { Toast } from "primereact/toast";
import { LOGIN, site } from "../../api/url_helper";
import { downloadExcelFactory } from "../../store/dashboard/slice";
import { formatDateApi } from "../../feature/formatDateApi";
import { useLocation } from "react-router-dom";
import axios from "axios";

export default function Report() {
    const dispatch = useDispatch();
    const toast = useRef(null);
    const downloadLink = useRef(null);
    const location = useLocation();
    const { nameUnit, selectedTeam } = useContext(DataContext);

    const [building, setBuilding] = useState(null);
    const [orderCode, setOrderCode] = useState("");
    const [category, setCategory] = useState("");
    const [factoryCode, setFactoryCode] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [nameBuilding, setNameBuilding] = useState("");
    const [pagination, setPagination] = useState({ first: 0, rows: 10 });
    const [pagination1, setPagination1] = useState({ first: 0, rows: 10 });
    const [
        selectedStatusProductInprogress,
        setSelectedStatusProductInprogress,
    ] = useState([]);
    const [allDataInprogress, setAllDataInprogress] = useState([]);
    const [allDataInlate, setAllDataInlate] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const [page, setPage] = useState(1);

    console.log("building", building);

    useEffect(() => {
        dispatch(resetPagination());
    }, [location.pathname, dispatch]);

    useEffect(() => {
        if (
            building ||
            orderCode ||
            category ||
            factoryCode ||
            startDate ||
            endDate ||
            selectedStatusProductInprogress
        ) {
            setAllDataInprogress([]);
            setAllDataInlate([]);
        }
    }, [
        building,
        orderCode,
        category,
        factoryCode,
        startDate,
        endDate,
        selectedStatusProductInprogress,
    ]);

    const handlePaginationChange = (first, rows) => {
        setPagination({ first, rows });
    };
    const handlePaginationChange1 = (first, rows) => {
        setPagination1({ first, rows });
    };

    const { buildingName } = useContext(DataContext);
    useEffect(() => {
        if (buildingName) setNameBuilding(buildingName);
    }, [buildingName]);

    const {
        dashboardFactoryData,
        productInlateFactory,
        productInprogressFactory,
        currentPage,
        allData,
        currentPageInlate,
        allDataLate,
        hasMoreData,
    } = useSelector(
        (state) => ({
            dashboardFactoryData: state.Dashboard.dashboardFactoryData,
            productInlateFactory: state.Dashboard.productInlateFactory,
            productInprogressFactory: state.Dashboard.productInprogressFactory,
            currentPage: state.Dashboard.currentPage,
            allData: state.Dashboard.allData,
            currentPageInlate: state.Dashboard.currentPageInlate,
            allDataLate: state.Dashboard.allDataInlate,
            hasMoreData: state.Dashboard.hasMoreData,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (hasMoreData) setPage(page + 1);
    }, [hasMoreData]);

    useEffect(() => {
        const isInprogressLoaded =
            allDataInprogress.length === allData?.data?.totalItems;
        const isInlateLoaded =
            allDataInlate.length === allDataLate?.data?.totalItems;

        if (isInprogressLoaded && isInlateLoaded) {
            setIsDataLoaded(true);
        } else {
            setIsDataLoaded(false);
        }
    }, [allDataInprogress, allDataInlate]);

    useEffect(() => {
        if (allDataLate?.data?.items) {
            setAllDataInlate((prevData) => {
                const existingIds = new Set(prevData.map((item) => item.id));

                const newItems = allDataLate.data.items.filter(
                    (item) => !existingIds.has(item.id)
                );

                return [...prevData, ...newItems];
            });
        }
    }, [
        allDataLate,
        currentPageInlate,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
    ]);

    useEffect(() => {
        if (allData?.data?.items) {
            setAllDataInprogress((prevData) => {
                const existingIds = new Set(prevData.map((item) => item.id));

                const newItems = allData.data.items.filter(
                    (item) => !existingIds.has(item.id)
                );

                return [...prevData, ...newItems];
            });
        }
    }, [
        allData,
        currentPage,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        selectedStatusProductInprogress,
    ]);

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                getProductInlateFactory({
                    page:
                        (pagination1.first + pagination1.rows) /
                        pagination1.rows,
                    limit: pagination1.rows,
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    type: "late",
                    filterBuildings: JSON.stringify(building),
                    orderCode,
                    category,
                    factoryCode,
                    filterUnit: JSON.stringify(nameUnit),
                    filterTeamWorking: JSON.stringify(selectedTeam),
                })
            );
        }
    }, [
        dispatch,
        pagination1,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        nameUnit,
        selectedTeam,
    ]);

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                getProductInprogressFactory({
                    page:
                        (pagination.first + pagination.rows) / pagination.rows,
                    limit: pagination.rows,
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    filterBuildings: JSON.stringify(building),
                    orderCode,
                    category,
                    factoryCode,
                    filterStatus: JSON.stringify(
                        selectedStatusProductInprogress
                    ),
                    filterUnit: JSON.stringify(nameUnit),
                    filterTeamWorking: JSON.stringify(selectedTeam),
                })
            );
        }
    }, [
        dispatch,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        pagination,
        selectedStatusProductInprogress,
        nameUnit,
        selectedTeam,
    ]);

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                getDashboardFactory({
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    filterBuildings: JSON.stringify(building),
                    orderCode,
                    category,
                    factoryCode,
                    filterUnit: JSON.stringify(nameUnit),
                    filterTeamWorking: JSON.stringify(selectedTeam),
                })
            );
        }
    }, [
        dispatch,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        nameUnit,
        selectedTeam,
    ]);

    //export excel
    const { buildingData, excelDataFactory, errorExcelDataFactory } =
        useSelector(
            (state) => ({
                excelDataFactory: state.Dashboard.excelDataFactory,
                errorExcelDataFactory: state.Dashboard.errorExcelDataFactory,
                buildingData: state.Production.dataBuilding,
            }),
            shallowEqual
        );

    const exportExcelHandle = () => {
        const id = buildingData?.data?.items.find(
            (item) => item.buildingCode === building
        )?.id;

        if (building) {
            dispatch(
                downloadExcelFactory({
                    building_id: building,
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        } else {
            dispatch(
                downloadExcelFactory({
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        }
    };

    useEffect(() => {
        if (excelDataFactory) {
            const url = `${site}/${excelDataFactory.file}`;
            toast.current.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xuất excel thành công.",
                life: 3000,
            });
            dispatch(setExcelDataBuildingNull());

            // Tự động tải file
            downloadLink.current.href = url;
            downloadLink.current.download = "exported_data.xlsx";
            downloadLink.current.click();
        }
    }, [dispatch, excelDataFactory]);
    const token = localStorage.getItem("accessToken");

    useEffect(() => {
        if (errorExcelDataFactory) {
            toast.current.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorExcelDataFactory.response.data.message,
                life: 3000,
            });
            dispatch(setExcelDataBuildingNull());
        }
    }, [dispatch, errorExcelDataFactory]);

    const handleExportClick = async (type) => {
        if (startDate && endDate) {
            try {
                // Fetch the first page's data to get the total pages for pagination
                const response = await axios.get(
                    `${site}/api/dashboard/factory-statistics/products-inprogress`,
                    {
                        params: {
                            page: page,
                            limit: 100,
                            timeStartReq: startDate,
                            timeEndReq: endDate,
                            filterBuildings: JSON.stringify(building),
                            orderCode,
                            category,
                            factoryCode,
                            filterStatus: JSON.stringify(
                                selectedStatusProductInprogress
                            ),
                            filterUnit: JSON.stringify(nameUnit),
                            filterTeamWorking: JSON.stringify(selectedTeam),
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json", // Fix header key
                        },
                        withCredentials: true,
                    }
                );
                const responseInlate = await axios.get(
                    `${site}/api/dashboard/factory-statistics/products-inlate`,
                    {
                        params: {
                            page: page,
                            limit: 100,
                            type: "late",
                            timeStartReq: startDate,
                            timeEndReq: endDate,
                            filterBuildings: JSON.stringify(building),
                            orderCode,
                            category,
                            factoryCode,
                            filterUnit: JSON.stringify(nameUnit),
                            filterTeamWorking: JSON.stringify(selectedTeam),
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json", // Fix header key
                        },
                        withCredentials: true,
                    }
                );

                const totalPages = response?.data?.data?.totalPages;
                const totalPagesInlate = responseInlate?.data?.data?.totalPages;

                const allDataArray = [];
                const requests = [];
                const allDataArrayInlate = [];
                const requestsInlate = [];

                // Fetch data for all pages concurrently
                for (let page = 1; page <= totalPages; page++) {
                    requests.push(
                        axios.get(
                            `${site}/api/dashboard/factory-statistics/products-inprogress`,
                            {
                                params: {
                                    page: page,
                                    limit: 100,
                                    timeStartReq: startDate,
                                    timeEndReq: endDate,
                                    filterBuildings: JSON.stringify(building),
                                    orderCode,
                                    category,
                                    factoryCode,
                                    filterStatus: JSON.stringify(
                                        selectedStatusProductInprogress
                                    ),
                                    filterUnit: JSON.stringify(nameUnit),
                                    filterTeamWorking:
                                        JSON.stringify(selectedTeam),
                                },
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "application/json", // Fix header key
                                },
                                withCredentials: true,
                            }
                        )
                    );
                }
                for (let page = 1; page <= totalPagesInlate; page++) {
                    requestsInlate.push(
                        axios.get(
                            `${site}/api/dashboard/factory-statistics/products-inlate`,
                            {
                                params: {
                                    page: page,
                                    limit: 100,
                                    type: "late",
                                    timeStartReq: startDate,
                                    timeEndReq: endDate,
                                    filterBuildings: JSON.stringify(building),
                                    orderCode,
                                    category,
                                    factoryCode,
                                    filterUnit: JSON.stringify(nameUnit),
                                    filterTeamWorking:
                                        JSON.stringify(selectedTeam),
                                },
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "application/json", // Fix header key
                                },
                                withCredentials: true,
                            }
                        )
                    );
                }

                const responses = await Promise.all(requests);
                const responsesInlate = await Promise.all(requestsInlate);

                responses.forEach((res) => {
                    allDataArray.push(...res.data?.data?.items);
                });
                responsesInlate.forEach((res) => {
                    allDataArrayInlate.push(...res.data?.data?.items);
                });

                // Trigger the export logic
                exportFactoryPDF(
                    type,
                    FormatDate(startDate),
                    FormatDate(endDate),
                    dashboardFactoryData?.data,
                    allDataArray,
                    allDataArrayInlate,
                    nameBuilding
                );
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.current.show({
                    severity: "error",
                    summary: "Lỗi",
                    detail: "Có lỗi xảy ra khi tải dữ liệu.",
                    life: 3000,
                });
            }
        } else {
            console.log("Start Date or End Date is missing");
        }
    };

    return (
        <div>
            <div>
                <Toast ref={toast} />
                <a ref={downloadLink} style={{ display: "none" }} />
                <HeaderReport
                    building={building}
                    orderCode={orderCode}
                    category={category}
                    factoryCode={factoryCode}
                    setBuilding={setBuilding}
                    setOrderCode={setOrderCode}
                    setCategory={setCategory}
                    setFactoryCode={setFactoryCode}
                    setStartDateOutput={setStartDate}
                    setEndDateOutput={setEndDate}
                    exportClick={(e) => handleExportClick("save")}
                    printReport={(e) => handleExportClick("print")}
                    exportExcel={exportExcelHandle}
                />
            </div>
            <HeaderReportFactory dashboardFactoryData={dashboardFactoryData} />
            <div
                id="header-pdf"
                style={{
                    width: "1000px",
                    height: "100px",
                    backgroundColor: "white",
                    position: "absolute",
                    left: "-9999999999px",
                    transform: "scale(0.5)",
                }}
            >
                <HeaderReportFactoryPDF
                    dashboardFactoryData={dashboardFactoryData}
                />
            </div>
            <div
                id="donut-chart"
                style={{
                    width: "400px",
                    height: "450px",
                    marginBottom: "1rem",
                    position: "absolute",
                    left: "-9999999999px",
                }}
            >
                <DonutChartPDF dashboardFactoryData={dashboardFactoryData} />
            </div>
            <div className="flex mt-3 gap-3 flex-column md:flex-row ">
                <div
                    className="bg-white border-round-lg py-4 chart-factory shadow-1"
                    style={{ width: "calc(25% - 11px)" }}
                >
                    <DonutChart dashboardFactoryData={dashboardFactoryData} />
                </div>
                <div
                    className="bg-white border-round-lg px-3 shadow-1 chart-factory"
                    style={{ width: "calc(75% - 4px)" }}
                >
                    <ProductInprogress
                        productInprogressFactory={productInprogressFactory}
                        onPaginationChange={handlePaginationChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectedStatusProductInprogress={
                            selectedStatusProductInprogress
                        }
                        setSelectedStatusProductInprogress={
                            setSelectedStatusProductInprogress
                        }
                    />
                </div>
            </div>
            <div className="mt-3 w-full shadow-1 border-round-lg">
                <ProductInlate
                    productInlateFactory={productInlateFactory}
                    onPaginationChange={handlePaginationChange1}
                />
            </div>
        </div>
    );
}
