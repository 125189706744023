import React from "react";
import ReactApexChart from "react-apexcharts";
import LoadingSpinner from "../Report/LoadingSpinner";

const DonutChart = ({ dashboardFactoryData }) => {
    const data = dashboardFactoryData?.data?.totalBomReport?.map(
        (item, index) => ({
            ...item,
            color: ["#0c4da2", "#1b8000", "#0000ff", "#fea500", "#ff0000"][
                index
            ],
        })
    );

    const options = {
        borderWidth: 0,
        chart: {
            type: "donut",
        },
        legend: {
            show: false,
        },
        series: data?.map((e) => Number(e.count)) || [],
        labels: data?.map((e) => e.status) || [],
        colors: data?.map((e) => e.color) || [],
        dataLabels: { enabled: false },
        plotOptions: {
            pie: {
                donut: {
                    size: "75%",
                },
                startAngle: 0,
                endAngle: 360,
            },
        },
    };
    return (
        <div className="w-full">
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "2rem",
                }}
            >
                <p className="element-p">Tổng BOM sản xuất ra</p>
                <p
                    style={{
                        marginTop: "1rem",
                        fontSize: "32px",
                        fontWeight: "700",
                    }}
                >
                    {data
                        ?.reduce((acc, item) => acc + Number(item.count), 0)
                        .toLocaleString("en-US")}
                </p>
            </div>
            {dashboardFactoryData && dashboardFactoryData.data ? (
                <div>
                    <ReactApexChart
                        options={options}
                        series={options.series}
                        type="donut"
                        height={"200px"}
                    />
                    <div className="mt-4">
                        {data &&
                            data.map((item) => (
                                <div
                                    key={item.status}
                                    className="grid gap-1 mx-7"
                                    style={{ alignItems: "center" }}
                                >
                                    <div
                                        className="dot"
                                        style={{
                                            backgroundColor: `${item.color}`,
                                        }}
                                    ></div>
                                    <p className="col-8">{item.status}</p>
                                    <p className="col-2 flex justify-content-end">
                                        {Number(item.count).toLocaleString(
                                            "en-US"
                                        )}
                                    </p>
                                </div>
                            ))}
                    </div>
                </div>
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );
};

export default DonutChart;
