import "./ReportFactory.scss";
import "../../assets/scss/theme-base/dashboard.css";
import { useNavigate } from "react-router-dom";

const HeaderReportFactory = ({ dashboardFactoryData }) => {
    const listPermission = JSON.parse(localStorage.getItem("userPermissions"));

    const navigate = useNavigate();
    return (
        <div className="flex md:flex-row flex-column mt-3 gap-3">
            <div
                className="header-container p-3 md:w-3 w-12 shadow-1"
                style={{ justifyContent: "space-between" }}
            >
                <div className="flex">
                    <div
                        className="icon"
                        style={{ backgroundColor: "#E4E4FF" }}
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2.5 6.5C2.5 4.61438 2.5 3.67157 3.08579 3.08579C3.67157 2.5 4.61438 2.5 6.5 2.5C8.38562 2.5 9.32843 2.5 9.91421 3.08579C10.5 3.67157 10.5 4.61438 10.5 6.5C10.5 8.38562 10.5 9.32843 9.91421 9.91421C9.32843 10.5 8.38562 10.5 6.5 10.5C4.61438 10.5 3.67157 10.5 3.08579 9.91421C2.5 9.32843 2.5 8.38562 2.5 6.5Z"
                                stroke="#424A67"
                                strokeWidth="1.5"
                            />
                            <path
                                d="M13.5 17.5C13.5 15.6144 13.5 14.6716 14.0858 14.0858C14.6716 13.5 15.6144 13.5 17.5 13.5C19.3856 13.5 20.3284 13.5 20.9142 14.0858C21.5 14.6716 21.5 15.6144 21.5 17.5C21.5 19.3856 21.5 20.3284 20.9142 20.9142C20.3284 21.5 19.3856 21.5 17.5 21.5C15.6144 21.5 14.6716 21.5 14.0858 20.9142C13.5 20.3284 13.5 19.3856 13.5 17.5Z"
                                stroke="#424A67"
                                strokeWidth="1.5"
                            />
                            <path
                                d="M2.5 17.5C2.5 15.6144 2.5 14.6716 3.08579 14.0858C3.67157 13.5 4.61438 13.5 6.5 13.5C8.38562 13.5 9.32843 13.5 9.91421 14.0858C10.5 14.6716 10.5 15.6144 10.5 17.5C10.5 19.3856 10.5 20.3284 9.91421 20.9142C9.32843 21.5 8.38562 21.5 6.5 21.5C4.61438 21.5 3.67157 21.5 3.08579 20.9142C2.5 20.3284 2.5 19.3856 2.5 17.5Z"
                                stroke="#424A67"
                                strokeWidth="1.5"
                            />
                            <path
                                d="M13.5 6.5C13.5 4.61438 13.5 3.67157 14.0858 3.08579C14.6716 2.5 15.6144 2.5 17.5 2.5C19.3856 2.5 20.3284 2.5 20.9142 3.08579C21.5 3.67157 21.5 4.61438 21.5 6.5C21.5 8.38562 21.5 9.32843 20.9142 9.91421C20.3284 10.5 19.3856 10.5 17.5 10.5C15.6144 10.5 14.6716 10.5 14.0858 9.91421C13.5 9.32843 13.5 8.38562 13.5 6.5Z"
                                stroke="#424A67"
                                strokeWidth="1.5"
                            />
                        </svg>
                    </div>
                    <div className="content" style={{ marginLeft: "1rem" }}>
                        <p className="content-p">Tổng số hạng mục</p>
                        <p>
                            {dashboardFactoryData?.data?.countTotalCategories.toLocaleString('en-US')}
                        </p>
                    </div>
                </div>
            </div>
            <div
                className="header-container p-3 md:w-3 md:w-3 w-12 cursor-pointer shadow-1"
                style={{ justifyContent: "space-between" }}
                onClick={
                    listPermission["Kỹ thuật"]?.includes("Xem thông tin")
                        ? () => navigate("/technique", { state: "notMaterial" })
                        : () => {}
                }
            >
                <div className="flex">
                    <div
                        className="icon"
                        style={{ backgroundColor: "#FDEAE8" }}
                    >
                        <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13.9987 14.0002L10.548 17.4173C7.13848 20.7937 5.43369 22.4819 5.91096 23.9384C5.95198 24.0636 6.00293 24.1854 6.06332 24.3027C6.7659 25.6668 9.17683 25.6668 13.9987 25.6668C18.8206 25.6668 21.2315 25.6668 21.9341 24.3027C21.9945 24.1854 22.0454 24.0636 22.0864 23.9384C22.5637 22.4819 20.8589 20.7937 17.4493 17.4172L13.9987 14.0002ZM13.9987 14.0002L17.4493 10.5831C20.8589 7.20666 22.5637 5.51846 22.0864 4.0619C22.0454 3.93669 21.9945 3.81489 21.9341 3.69764C21.2315 2.3335 18.8206 2.3335 13.9987 2.3335C9.17683 2.3335 6.7659 2.3335 6.06332 3.69764C6.00293 3.81489 5.95198 3.93669 5.91096 4.0619C5.43369 5.51846 7.13848 7.20666 10.5481 10.5831L13.9987 14.0002Z"
                                stroke="#FF5C00"
                                strokeWidth="1.5"
                            />
                        </svg>
                    </div>
                    <div className="content" style={{ marginLeft: "1rem" }}>
                        <p className="content-p">Chưa đặt vật tư</p>
                        <p>
                            {dashboardFactoryData?.data?.countProductsWithoutMaterialOrdering.toLocaleString('en-US')}
                        </p>
                    </div>
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 1024 1024"
                >
                    <path
                        fill="currentColor"
                        d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8l-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0"
                    />
                </svg>
            </div>
            <div
                className="header-container p-3 md:w-3 w-12 cursor-pointer shadow-1"
                style={{ justifyContent: "space-between" }}
                onClick={
                    listPermission["Kỹ thuật"]?.includes("Xem thông tin")
                        ? () =>
                              navigate("/technique", { state: "notMachining" })
                        : () => {}
                }
            >
                <div className="flex">
                    <div
                        className="icon"
                        style={{ backgroundColor: "#FFF0D5" }}
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M22 14V12C22 8.22876 22 6.34315 20.8284 5.17157C19.6569 4 17.7712 4 14 4H10C6.22876 4 4.34315 4 3.17157 5.17157C2 6.34315 2 8.22876 2 12V14C2 17.7712 2 19.6569 3.17157 20.8284C4.34315 22 6.22876 22 10 22H14"
                                stroke="#FF5C00"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            />
                            <path
                                d="M7 4V2.5"
                                stroke="#FF5C00"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            />
                            <path
                                d="M17 4V2.5"
                                stroke="#FF5C00"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            />
                            <circle
                                cx="18"
                                cy="18"
                                r="3"
                                stroke="#FF5C00"
                                strokeWidth="1.5"
                            />
                            <path
                                d="M20.5 20.5L22 22"
                                stroke="#FF5C00"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            />
                            <path
                                d="M2.5 9H21.5"
                                stroke="#FF5C00"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            />
                        </svg>
                    </div>
                    <div className="content" style={{ marginLeft: "1rem" }}>
                        <p className="content-p">Chưa đặt hàng gia công</p>
                        <p>
                            {dashboardFactoryData?.data?.countProductsWithoutMachiningOrdering.toLocaleString('en-US')}
                        </p>
                    </div>
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 1024 1024"
                >
                    <path
                        fill="currentColor"
                        d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8l-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0"
                    />
                </svg>
            </div>
            <div
                className="header-container p-3 md:w-3 w-12  cursor-pointer shadow-1"
                style={{ justifyContent: "space-between" }}
                onClick={
                    listPermission["Kỹ thuật"]?.includes("Xem thông tin")
                        ? () => navigate("/technique", { state: "Chưa có BOM" })
                        : () => {}
                }
            >
                <div className="flex">
                    <div
                        className="icon"
                        style={{ backgroundColor: "#edeff0" }}
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M22 10.5V12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2H13.5"
                                stroke="#FF5C00"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            />
                            <path
                                d="M7 14H16"
                                stroke="#FF5C00"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            />
                            <path
                                d="M7 17.5H13"
                                stroke="#FF5C00"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            />
                            <path
                                d="M22 2L17 6.99998M17 1.99998L22 6.99996"
                                stroke="#FF5C00"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            />
                        </svg>
                    </div>
                    <div className="content" style={{ marginLeft: "1rem" }}>
                        <p className="content-p">Chưa có BOM</p>
                        <p>
                            {dashboardFactoryData?.data?.countProductsWithoutBomProduction.toLocaleString('en-US')}
                        </p>
                    </div>
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 1024 1024"
                >
                    <path
                        fill="currentColor"
                        d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8l-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0"
                    />
                </svg>
            </div>
        </div>
    );
};

export default HeaderReportFactory;
