import React, { useEffect, useState, useContext, useRef } from "react";
import HeaderReport from "../../layout/HeaderReport/HeaderReport";
// import HeaderReportConstruction from "./HeaderReportConstruction";
import DonutChart from "./DonutChart";
import ProductInprogress from "./ProductInprogress";
import DelayProgress from "./DelayProgress";
import HeaderReportFactory from "../Report/HeaderReportFactory";
import Complete from "./Complete";
import DonutChartPDF from "./DonutChartPDF";
import {
    getDashboardFactory,
    getProductInlateFactory,
    getProductInprogressFactory,
    getProductIncompleteFactory,
    downloadExcelBuilding,
    setExcelDataBuildingNull,
    resetPagination,
    getAllProductInprogress,
} from "../../store/dashboard/slice";
import { exportExcel5, setDataNull2 } from "../../store/order/slice";
import { getBuilding } from "../../store/order/slice";
import { getReportQuality } from "../../store/quality/slice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { exportConstructionPDF } from "./ExportConstructionPDF";
import FormatDate from "../Report/FormatDate";
import "../Report/ReportFactory.scss";
import HeaderReportFactoryPDF from "../Report/HeaderReportFactoryPDF";
import { DataContext } from "../../contexts/data/DataProvider";
import { exportImagePDF } from "./ExportImagePDF";
import { exportQualityPDF } from "./ExportQualityPDF";
import { Toast } from "primereact/toast";
import { site } from "../../api/url_helper";
import axios from "axios";
import { formatDateApi } from "../../feature/formatDateApi";
import { useLocation } from "react-router-dom";

export default function Construction1() {
    const dispatch = useDispatch();
    const toast = useRef(null);
    const downloadLink = useRef(null);
    const location = useLocation();
    const { setPrefix } = useContext(DataContext);
    const { nameUnit, selectedTeam } = useContext(DataContext);

    const [building, setBuilding] = useState(null);
    const [orderCode, setOrderCode] = useState("");
    const [category, setCategory] = useState("");
    const [factoryCode, setFactoryCode] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const [nameBuilding, setNameBuilding] = useState("");
    const [reportImage, setReportImage] = useState(null);
    const [pagination, setPagination] = useState({ first: 0, rows: 10 });
    const [pagination1, setPagination1] = useState({ first: 0, rows: 10 });
    const [pagination2, setPagination2] = useState({ first: 0, rows: 10 });
    const [
        selectedStatusProductInprogress,
        setSelectedStatusProductInprogress,
    ] = useState([]);
    const [
        selectedStatusProductInComplete,
        setSelectedStatusProductInComplete,
    ] = useState([]);
    const [allDataInprogress, setAllDataInprogress] = useState([]);
    const [page, setPage] = useState(1);
    const token = localStorage.getItem("accessToken");
    const isGuest = localStorage.getItem("isGuest");
    const isPm = localStorage.getItem("isPm");

    const { currentPage, allData } = useSelector(
        (state) => ({
            currentPage: state.Dashboard.currentPage,
            allData: state.Dashboard.allData,
        }),
        shallowEqual
    );

    useEffect(() => {
        dispatch(resetPagination());
    }, [location.pathname, dispatch]);

    useEffect(() => {
        if (
            building ||
            orderCode ||
            category ||
            factoryCode ||
            startDate ||
            endDate ||
            selectedStatusProductInprogress
        ) {
            setAllDataInprogress([]);
        }
    }, [
        building,
        orderCode,
        category,
        factoryCode,
        startDate,
        endDate,
        selectedStatusProductInprogress,
    ]);

    useEffect(() => {
        if (allData?.data?.items) {
            setAllDataInprogress((prevData) => {
                const existingIds = new Set(prevData.map((item) => item.id));

                const newItems = allData.data.items.filter(
                    (item) => !existingIds.has(item.id)
                );

                return [...prevData, ...newItems];
            });
        }
    }, [
        allData,
        currentPage,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        selectedStatusProductInprogress,
    ]);

    const handlePaginationChange = (first, rows) => {
        setPagination({ first, rows });
    };
    const handlePaginationChange1 = (first, rows) => {
        setPagination1({ first, rows });
    };

    const handlePaginationChange2 = (first, rows) => {
        setPagination2({ first, rows });
    };

    const dataBuilding = useSelector(
        (state) => state.Production.dataBuilding,
        shallowEqual
    );

    const limit = dataBuilding?.data?.totalItems;

    useEffect(() => {
        dispatch(
            getBuilding({
                page: (first + rows) / rows,
                limit: 100,
            })
        );
    }, [dispatch, first, rows]);

    //lấy building ID
    const getIdsByBuildingCode = (code) => {
        return dataBuilding?.data?.items
            ?.filter((item) => item.buildingCode === code)
            .map((item) => item.id)[0];
    };
    const buildingId = getIdsByBuildingCode(building);

    const { buildingName } = useContext(DataContext);
    useEffect(() => {
        if (buildingName) setNameBuilding(buildingName);
    }, [buildingName]);

    //report quality
    const reportQuality = useSelector(
        (state) => state.ReportQuality.reportQuality,
        shallowEqual
    );

    useEffect(() => {
        if (building) {
            dispatch(
                getReportQuality({ buildingId: JSON.stringify(building) })
            );
        }
    }, [dispatch, building]);

    //report Image
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${site}/api/teams/productions`,
                    {
                        params: { buildingId: JSON.stringify(building) }, // Add building in the query parameters
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json", // Fix header key
                            withCredentials: true,
                        },
                    }
                );
                setReportImage(response.data);
            } catch (err) {
                console.log(err);
            }
        };

        if (building) {
            fetchData();
        }
    }, [building]);

    //dashboardFactory
    const dashboardFactoryData = useSelector(
        (state) => state.Dashboard.dashboardFactoryData,
        shallowEqual
    );
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (startDate && endDate) {
                dispatch(
                    getDashboardFactory({
                        timeStartReq: startDate,
                        timeEndReq: endDate,
                        filterBuildings: JSON.stringify(building),
                        orderCode,
                        category,
                        factoryCode,
                        current: "buildingStatistics",
                        filterUnit: JSON.stringify(nameUnit),
                        filterTeamWorking: JSON.stringify(selectedTeam),
                    })
                );
            }
        }, 400);
        return () => clearTimeout(timeout);
    }, [
        dispatch,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        nameUnit,
        selectedTeam,
    ]);

    //productInlate
    const productInlateFactory = useSelector(
        (state) => state.Dashboard.productInlateFactory,
        shallowEqual
    );
    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                getProductInlateFactory({
                    page:
                        (pagination.first + pagination.rows) / pagination.rows,
                    limit: pagination.rows,
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    filterBuildings: JSON.stringify(building),
                    orderCode,
                    category,
                    factoryCode,
                    current: "buildingStatistics",
                    type: "factory",
                    filterUnit: JSON.stringify(nameUnit),
                    filterTeamWorking: JSON.stringify(selectedTeam),
                })
            );
        }
    }, [
        dispatch,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        pagination,
        nameUnit,
        selectedTeam,
    ]);

    //productInprogress
    const productInprogressFactory = useSelector(
        (state) => state.Dashboard.productInprogressFactory,
        shallowEqual
    );

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                getProductInprogressFactory({
                    page:
                        (pagination1.first + pagination1.rows) /
                        pagination1.rows,
                    limit: pagination1.rows,
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    filterBuildings: JSON.stringify(building),
                    orderCode,
                    category,
                    factoryCode,
                    current: "buildingStatistics",
                    filterStatus: JSON.stringify(
                        selectedStatusProductInprogress
                    ),
                    filterUnit: JSON.stringify(nameUnit),
                    filterTeamWorking: JSON.stringify(selectedTeam),
                })
            );
        }
    }, [
        dispatch,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        pagination1,
        selectedStatusProductInprogress,
        nameUnit,
        selectedTeam,
    ]);

    //productIncomplete
    const productIncompleteFactory = useSelector(
        (state) => state.Dashboard.productIncompleteFactory,
        shallowEqual
    );

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                getProductIncompleteFactory({
                    page:
                        (pagination2.first + pagination2.rows) /
                        pagination2.rows,
                    limit: pagination2.rows,
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    filterBuildings: JSON.stringify(building),
                    orderCode,
                    category,
                    factoryCode,
                    current: "buildingStatistics",
                    filterStatus: JSON.stringify(
                        selectedStatusProductInComplete
                    ),
                    filterUnit: JSON.stringify(nameUnit),
                    filterTeamWorking: JSON.stringify(selectedTeam),
                })
            );
        }
    }, [
        dispatch,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        pagination2,
        selectedStatusProductInComplete,
        nameUnit,
        selectedTeam,
    ]);

    //export excel
    const { buildingData, excelDataBuilding, errorExcelDataBuilding } =
        useSelector(
            (state) => ({
                excelDataBuilding: state.Dashboard.excelDataBuilding,
                errorExcelDataBuilding: state.Dashboard.errorExcelDataBuilding,
                buildingData: state.Production.dataBuilding,
            }),
            shallowEqual
        );
    // console.log("buildingData", buildingData);

    const exportExcelHandle = () => {
        const id = buildingData?.data?.items.find(
            (item) => item.buildingCode === building
        )?.id;

        if (building) {
            dispatch(
                downloadExcelBuilding({
                    building_id: building,
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        } else {
            dispatch(
                downloadExcelBuilding({
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        }
    };

    useEffect(() => {
        if (excelDataBuilding) {
            const url = `${site}/${excelDataBuilding.file}`;
            toast.current.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xuất excel thành công.",
                life: 3000,
            });

            // Tự động tải file
            downloadLink.current.href = url;
            downloadLink.current.download = "exported_data.xlsx";
            downloadLink.current.click();

            dispatch(setExcelDataBuildingNull());
        }
    }, [dispatch, excelDataBuilding]);

    useEffect(() => {
        if (errorExcelDataBuilding) {
            toast.current.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorExcelDataBuilding.response.data.message,
                life: 3000,
            });
            dispatch(setExcelDataBuildingNull());
        }
    }, [dispatch, errorExcelDataBuilding]);

    useEffect(() => {
        setPrefix(dashboardFactoryData?.data?.prefix);
    }, [dashboardFactoryData]);

    const handleExportClick = async (type) => {
        if (startDate && endDate) {
            try {
                // Fetch the first page's data to get the total pages for pagination
                const response = await axios.get(
                    `${site}/api/dashboard/factory-statistics/products-inprogress`,
                    {
                        params: {
                            page: page,
                            limit: 100,
                            timeStartReq: startDate,
                            timeEndReq: endDate,
                            filterBuildings: JSON.stringify(building),
                            orderCode,
                            category,
                            factoryCode,
                            current: "buildingStatistics",

                            filterStatus: JSON.stringify(
                                selectedStatusProductInprogress
                            ),
                            filterUnit: JSON.stringify(nameUnit),
                            filterTeamWorking: JSON.stringify(selectedTeam),
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json", // Fix header key
                        },
                        withCredentials: true,
                    }
                );

                const totalPages = response?.data?.data?.totalPages;

                const allDataArray = [];
                const requests = [];

                // Fetch data for all pages concurrently
                for (let page = 1; page <= totalPages; page++) {
                    requests.push(
                        axios.get(
                            `${site}/api/dashboard/factory-statistics/products-inprogress`,
                            {
                                params: {
                                    page: page,
                                    limit: 100,
                                    timeStartReq: startDate,
                                    timeEndReq: endDate,
                                    filterBuildings: JSON.stringify(building),
                                    orderCode,
                                    category,
                                    factoryCode,
                                    current: "buildingStatistics",

                                    filterStatus: JSON.stringify(
                                        selectedStatusProductInprogress
                                    ),
                                    filterUnit: JSON.stringify(nameUnit),
                                    filterTeamWorking:
                                        JSON.stringify(selectedTeam),
                                },
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "application/json", // Fix header key
                                },
                                withCredentials: true,
                            }
                        )
                    );
                }

                const responses = await Promise.all(requests);

                responses.forEach((res) => {
                    allDataArray.push(...res.data?.data?.items);
                });

                // Trigger the export logic
                exportConstructionPDF(
                    type,
                    FormatDate(startDate),
                    FormatDate(endDate),
                    dashboardFactoryData?.data,
                    productInlateFactory?.data?.items,
                    allDataArray,
                    productIncompleteFactory?.data?.items,
                    nameBuilding
                );
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.current.show({
                    severity: "error",
                    summary: "Lỗi",
                    detail: "Có lỗi xảy ra khi tải dữ liệu.",
                    life: 3000,
                });
            }
        } else {
            console.log("Start Date or End Date is missing");
        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <a ref={downloadLink} style={{ display: "none" }} />
            <div>
                <HeaderReport
                    building={building}
                    orderCode={orderCode}
                    category={category}
                    factoryCode={factoryCode}
                    setBuilding={setBuilding}
                    setOrderCode={setOrderCode}
                    setCategory={setCategory}
                    setFactoryCode={setFactoryCode}
                    setStartDateOutput={setStartDate}
                    setEndDateOutput={setEndDate}
                    exportClick={(e) => handleExportClick("save")}
                    printReport={(e) => handleExportClick("print")}
                    exportImage={(e) => {
                        if (!building) {
                            toast.current.show({
                                severity: "warn",
                                summary: "Warning",
                                detail: "Bạn chưa chọn công trình",
                                life: 5000,
                            });
                        } else if (reportImage) {
                            exportImagePDF(reportImage);
                        } else {
                            toast.current.show({
                                severity: "warn",
                                summary: "Warning",
                                detail: "Công trình này không có dữ liệu",
                                life: 5000,
                            });
                        }
                    }}
                    exportQuality={(e) => {
                        if (!building) {
                            toast.current.show({
                                severity: "warn",
                                summary: "Warning",
                                detail: "Bạn chưa chọn công trình",
                                life: 5000,
                            });
                        } else if (
                            reportQuality &&
                            reportQuality.data.length > 0
                        ) {
                            exportQualityPDF(reportQuality?.data);
                        } else {
                            toast.current.show({
                                severity: "warn",
                                summary: "Warning",
                                detail: "Công trình này không có dữ liệu",
                                life: 5000, // Toast will be visible for 5 seconds
                            });
                        }
                    }}
                    type="construction"
                    exportExcel={exportExcelHandle}
                />
            </div>
            <HeaderReportFactory dashboardFactoryData={dashboardFactoryData} />
            <div
                id="header-pdf"
                style={{
                    width: "1000px",
                    height: "100px",
                    position: "absolute",
                    backgroundColor: "white",
                    left: "-9999999999px",
                    transform: "scale(0.5)",
                }}
            >
                <HeaderReportFactoryPDF
                    dashboardFactoryData={dashboardFactoryData}
                />
            </div>
            <div
                id="donut-chart"
                style={{
                    width: "400px",
                    height: "450px",
                    position: "absolute",
                    left: "-9999999999px",
                }}
            >
                <DonutChartPDF dashboardFactoryData={dashboardFactoryData} />
            </div>
            {isGuest?.toString() === "true" && isPm?.toString() === "false" ? (
                <div className="flex mt-3 gap-3 flex-column md:flex-row ">
                    <div
                        className="bg-white border-round-lg py-4 chart-factory shadow-1"
                        style={{ width: "calc(25% - 10px)" }}
                    >
                        <DonutChart
                            dashboardFactoryData={dashboardFactoryData}
                        />
                    </div>
                    <div className="w-12 md:w-9 bg-white border-round-lg shadow-1">
                        <ProductInprogress
                            productInprogressFactory={productInprogressFactory}
                            onPaginationChange={handlePaginationChange1}
                            selectedStatusProductInprogress={
                                selectedStatusProductInprogress
                            }
                            setSelectedStatusProductInprogress={
                                setSelectedStatusProductInprogress
                            }
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <div className="flex mt-3 gap-3 flex-column md:flex-row ">
                        <div
                            className="bg-white border-round-lg py-4 chart-factory shadow-1"
                            style={{ width: "calc(25% - 10px)" }}
                        >
                            <DonutChart
                                dashboardFactoryData={dashboardFactoryData}
                            />
                        </div>
                        <div
                            className="w-12 md:w-9 bg-white border-round-lg shadow-1"
                            // style={{ width: "calc(66.6667% - 8px)" }}
                        >
                            <DelayProgress
                                productInlateFactory={productInlateFactory}
                                onPaginationChange={handlePaginationChange}
                            />
                        </div>
                    </div>
                    <div className="mt-3 shadow-1 border-round-lg">
                        <ProductInprogress
                            productInprogressFactory={productInprogressFactory}
                            onPaginationChange={handlePaginationChange1}
                            selectedStatusProductInprogress={
                                selectedStatusProductInprogress
                            }
                            setSelectedStatusProductInprogress={
                                setSelectedStatusProductInprogress
                            }
                        />
                    </div>
                </div>
            )}

            <div className="mt-3 shadow-1 border-round-lg">
                <Complete
                    productIncompleteFactory={productIncompleteFactory}
                    onPaginationChange={handlePaginationChange2}
                    selectedStatusProductInComplete={
                        selectedStatusProductInComplete
                    }
                    setSelectedStatusProductInComplete={
                        setSelectedStatusProductInComplete
                    }
                />
            </div>
        </div>
    );
}
