import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCustomersRequest } from "../../store/auth/slice";
import "./index.css";
import {
    clearPermission,
    getPermissionBuildingRequest,
    setBuildingPermissionRequest,
} from "../../store/permission/slice";
import { Paginator } from "primereact/paginator";
import axios from "axios";
import { site } from "../../api/url_helper";

const CustomerPermission = () => {
    const toast = useRef(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [checkedItems, setCheckedItems] = useState([]);
    const [initialCheckedItems, setInitialCheckedItems] = useState([]);
    const [listTrue, setListTrue] = useState([]);
    const [listFalse, setListFalse] = useState([]);

    const { customers, permissionBuilding, result, error } = useSelector(
        (state) => ({
            customers: state.Auth.customers.data,
            permissionBuilding: state.Permission.permissionBuilding.data,
            result: state.Permission.resultSetBuidingPermission.data,
            error: state.Permission.resultSetBuidingPermission.error,
        }),
        shallowEqual
    );

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getCustomersRequest({ page: (first + rows) / rows, limit: rows })
        );
    }, [dispatch, first, rows]);

    useEffect(() => {
        if (customers && customers.items && customers.items.length > 0) {
            setSelectedGroup(customers.items[0]);
        }
    }, [customers]);

    useEffect(() => {
        if (selectedGroup) {
            dispatch(getPermissionBuildingRequest(selectedGroup.id));
        }
    }, [selectedGroup, dispatch, result]);

    useEffect(() => {
        if (permissionBuilding && permissionBuilding.length > 0) {
            const checkedItems = permissionBuilding
                .filter((item) => item.check)
                .map((item) => item.id);
            setCheckedItems(checkedItems);
            setInitialCheckedItems(checkedItems);
        }
    }, [permissionBuilding]);

    const handleCheck = (key) => {
        const isCurrentlyChecked = checkedItems.includes(key);
        const isInitiallyChecked = initialCheckedItems.includes(key);

        let newCheckedItems = [];
        if (isCurrentlyChecked) {
            // Bỏ tích
            newCheckedItems = checkedItems.filter((item) => item !== key);
            if (isInitiallyChecked) {
                setListFalse((prev) => [...prev, key]); // Nếu ban đầu được tích, thêm vào listFalse
            }
            setListTrue((prev) => prev.filter((item) => item !== key)); // Loại khỏi listTrue nếu trước đó đã tích thêm
        } else {
            // Tích thêm
            newCheckedItems = [...checkedItems, key];
            if (!isInitiallyChecked) {
                setListTrue((prev) => [...prev, key]); // Nếu ban đầu không được tích, thêm vào listTrue
            }
            setListFalse((prev) => prev.filter((item) => item !== key)); // Loại khỏi listFalse nếu trước đó đã bỏ tích
        }

        setCheckedItems(newCheckedItems);
    };

    const renderedPermissions = useMemo(() => {
        return permissionBuilding?.map((permission) => (
            <div key={permission.id} className="w-6 xl:w-3 my-3">
                <div className="flex align-items-center">
                    <Checkbox
                        checked={checkedItems.includes(permission.id)}
                        onChange={() => handleCheck(permission.id)}
                        inputId={permission.id}
                        pt={{ root: { className: "custom-checkbox" } }}
                    />
                    <label
                        htmlFor={permission.id}
                        className="ml-2 cursor-pointer"
                    >
                        {permission.name}
                    </label>
                </div>
            </div>
        ));
    }, [permissionBuilding, checkedItems]);

    const handleSetBuildingPermission = () => {
        if (selectedGroup?.id)
            dispatch(
                setBuildingPermissionRequest({
                    userId: selectedGroup.id,
                    listTrue,
                    listFalse,
                })
            );
    };

    useEffect(() => {
        if (result) {
            toast.current.show({
                severity: "success",
                summary: "Thành công",
                detail: "Cập nhật quyền thành công!",
            });
            dispatch(getPermissionBuildingRequest(selectedGroup?.id));
            dispatch(clearPermission());
        }
        if (error) {
            toast.current.show({
                severity: "error",
                summary: "Thất bại",
                detail: error?.response?.data.message,
            });
            dispatch(clearPermission());
        }
    }, [dispatch, error, result, selectedGroup?.id]);
    const [checkedState, setCheckedState] = useState({});

    useEffect(() => {
        // Initialize the checkedState when customers data is available
        const initialCheckedState = customers?.items.reduce((acc, rowData) => {
            if (rowData.prefix === "pm-building") {
                acc[rowData?.id] = true;
            } else {
                acc[rowData?.id] = false;
            }
            return acc;
        }, {});

        setCheckedState(initialCheckedState);
    }, [customers]);

    const token = localStorage.getItem("accessToken");
    const handleCheckboxChange = async (e, rowData) => {
        const updatedCheckedState = { ...checkedState };
        updatedCheckedState[rowData.id] = e.checked;
        setCheckedState(updatedCheckedState);

        const prefix = e.checked ? "pm-building" : null;

        try {
            await axios.post(
                `${site}/api/users/set/prefix`,
                {
                    userId: rowData.id,
                    prefix: prefix,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                }
            );
        } catch (error) {
            console.error("API call failed for user:", error);
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="flex flex-column lg:flex-row gap-3 h-full">
                <div className="bg-white h-full border-round-md shadow-1 p-3 flex flex-column gap-2 w-full lg:w-4 xl:w-3">
                    <div className="flex justify-content-between align-items-center flex-wrap mb-2">
                        <h3>Danh sách khách hàng</h3>
                    </div>
                    <div className="border-0 h-full">
                        <DataTable
                            value={customers?.items}
                            onRowClick={(e) => setSelectedGroup(e.data)}
                            selection={selectedGroup}
                            selectionMode="single"
                            showHeaders={false}
                            emptyMessage="Chưa có khách hàng nào"
                            scrollable
                            scrollHeight="75vh"
                            className="border-1 border-300 overflow-hidden"
                            pt={{
                                root: { className: "custom-table" },
                            }}
                            style={{ borderRadius: "0.375rem" }}
                        >
                            <Column
                                field="name"
                                style={{ minWidth: "150px", height: "45px" }}
                                body={(rowData) => (
                                    <div className="flex ml-2 align-items-center justify-content-between">
                                        <div className="flex-column">
                                            <div className="font-bold">
                                                {rowData.name}
                                            </div>
                                            <div>{rowData.email}</div>
                                        </div>
                                        <Checkbox
                                            className="custom-checkbox111"
                                            checked={
                                                checkedState &&
                                                checkedState[rowData?.id] !==
                                                    undefined
                                                    ? checkedState[rowData?.id]
                                                    : false
                                            }
                                            onChange={(e) =>
                                                handleCheckboxChange(e, rowData)
                                            }
                                        />
                                    </div>
                                )}
                            />
                        </DataTable>
                    </div>
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={customers?.totalItems || 0}
                        alwaysShow={customers?.totalItems > 10}
                        rowsPerPageOptions={[10, 20, 30]}
                        onPageChange={onPageChange}
                    />
                </div>
                <div className="bg-white border-round-md shadow-1 p-3 flex flex-column gap-2 w-full lg:w-8 xl:w-9">
                    <div className="flex justify-content-between">
                        <h3
                            className="flex align-items-center"
                            style={{ height: "30px" }}
                        >
                            {selectedGroup?.groupName}
                        </h3>
                        {selectedGroup && (
                            <Button
                                label="Lưu"
                                style={{ height: "30px", width: "100px" }}
                                onClick={handleSetBuildingPermission}
                            />
                        )}
                    </div>
                    <div className="flex flex-column gap-1">
                        <div className="flex justify-content-around flex-wrap px-5 border-1 border-round-md border-300 ">
                            {renderedPermissions}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomerPermission;
