import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

let config = {
    headers: {
        "Content-Type": "application/json",
    },
};

let config_upload_file = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

//-------------------------------------------------------------------------------------------------------->

// users
export const login = (data) => post(url.LOGIN, data, config);
export const active = (data) => post(url.ACTIVE, data, config);
export const verify = (data) => post(url.VERIFY, data, config);
export const getByUserId = (query) =>
    get(`${url.GET_USER}`, { params: query }, config);
export const getUser = (query) =>
    get(`${url.GET_USER}`, { params: query }, config);
export const getUserDataByUserId = () =>
    get(`${url.GET_USER_BY_USER_ID}`, config);
export const changePassword = (data) => post(url.CHANGE_PASSWORD, data, config);
export const forgetPassword = (id) =>
    put(`${url.FORGET_PASSWORD}/${id}`, config);
export const addUser = (data) => post(url.ADD_USER, data, config);
export const updateUser = (id, data) =>
    post(`${url.GET_USER}/${id}`, data, config);
export const deleteUser = (id, data) =>
    del(`${url.GET_USER}/${id}`, { data: data }, config);
export const getAuth = () => get(`${url.GET_AUTH}`, config);

export const getCustomers = (query) =>
    get(`${url.CUSTOMERS}`, { params: query }, config);

//permission
export const getPermission = (query) =>
    get(`${url.GET_PERMISSION}`, { params: query }, config);
export const getAllPermissions = (query) =>
    get(`${url.GET_ALL_PERMISSION}`, { params: query }, config);
export const addGroupPermission = (data) =>
    post(url.GET_PERMISSION, data, config);
export const deleteGroupPermission = (id) =>
    del(`${url.GET_PERMISSION}/${id}`, config);
export const updateGroupPermission = (id, data) =>
    put(`${url.GET_PERMISSION}/${id}`, data, config);
export const getPermissionByGroupId = (query) =>
    get(`${url.GET_PERMISSION_BY_GROUP_ID}`, { params: query }, config);
export const setPermissionToGroup = (data) =>
    post(url.SET_PERMISSION_TO_GROUP, data, config);
export const getPermissionBuilding = (id) =>
    get(`${url.GET_BUILDING_PERMISSION}/${id}`, config);
export const setBuildingPermission = (data) =>
    post(url.SET_BUILDING_PERMISSION, data, config);

//production
export const getProductionAll = () => get(`${url.GET_PRODUCTION}`, config);

//construction

export const getConstruction = (query) =>
    get(`${url.GET_CONSTRUCTION}`, { params: query }, config);
export const addNewConstruction = (data) =>
    post(url.SET_CONSTRUCTION, data, config);
export const updateConstruction = (id, data) =>
    post(`${url.UPDATE_CONSTRUCTION}/${id}`, data, config);
export const deleteConstruction = (id, data) =>
    del(`${url.DELETE_CONSTRUCTION}/${id}`, { data: data }, config);

//teams

export const getTeams = (query) =>
    get(`${url.GET_TEAMS}`, { params: query }, config);
export const addNewTeams = (data) => post(url.SET_TEAMS, data, config);
export const deleteTeams = (id, data) =>
    del(`${url.DELETE_TEAMS}/${id}`, { data: data }, config);
export const updateTeams = (id, data) =>
    post(`${url.UPDATE_TEAMS}/${id}`, data, config);

export const addQrTeam = (data) =>
    post(url.ADD_QR_TEAM, data, config_upload_file);

export const addQrNote = (data) =>
    post(url.ADD_QR_NOTE, data, config_upload_file);

export const checkPassword = (data) => post(url.CHECK_PASSWORD, data, config);

//category

export const getCategory = (query) =>
    get(`${url.GET_CATEGORY}`, { params: query }, config);
export const addNewCategory = (data) => post(url.SET_CATEGORY, data, config);
export const deleteCategory = (id) =>
    del(`${url.DELETE_CATEGORY}/${id}`, config);
export const updateCategory = (data) => put(url.UPDATE_CATEGORY, data, config);

//oder
export const getOrder = (query) =>
    get(`${url.GET_ORDER}`, { params: query }, config);
export const getPlan = (query) =>
    get(`${url.GET_PLAN}`, { params: query }, config);
export const getStep = (query) =>
    get(`${url.GET_STEP}`, { params: query }, config);
export const setPlan = (data) => post(url.SET_PLAN, data, config);
export const setStep = (data) => post(url.GET_STEP, data, config);
export const updatePlan = (id, data) =>
    post(`${url.UPDATE_PLAN}/${id}`, data, config);
export const updateStep = (id, data) =>
    put(`${url.GET_STEP}/${id}`, data, config);
export const deletePlan = (id) => del(`${url.DELETE_PLAN}/${id}`, config);
export const deleteStep = (id) => del(`${url.GET_STEP}/${id}`, config);
export const setProduct = (data) =>
    post(url.SET_PRODUCT, data, config_upload_file);
export const getBuilding = (query) =>
    get(`${url.GET_BUILDING}`, { params: query }, config);

export const getTeamProgress = (id) =>
    get(`${url.GET_TEAM_PROGRESS}/${id}?limit=100`, config);

export const addPrice = (data) =>
    put(url.ADD_PRICE + `?current=price`, data, config);

export const addQr = (data) =>
    put(url.ADD_QR + `?current=buildingPlan`, data, config);

export const updateProduction = (data) =>
    put(url.UPDATE_PRODUCTION + `?current=updateTechnical`, data, config);

export const getTeamChildren = (query) =>
    get(`${url.GET_TEAM_CHILDREN}`, { params: query }, config);

export const deleteOrder = (data) =>
    put(url.GET_ORDER + `?current=deletePlan`, data, config);

export const editOrder = (data) =>
    put(url.GET_ORDER + `?current=updatePlan`, data, config_upload_file);

export const getDViFull = (id, data) =>
    get(`${url.GET_DVI_FULL}/${id}`, data, config);

//dashbordfactory
export const getDashboardFactory = (query) =>
    get(`${url.GET_DASHBOARD_FACTORY}`, { params: query }, config);
export const getProductInprogressFactory = (query) =>
    get(`${url.GET_PRODUCT_INPROGRESS_FACTORY}`, { params: query }, config);
export const getProductInlateFactory = (query) =>
    get(`${url.GET_PRODUCT_INLATE_FACTORY}`, { params: query }, config);
export const getProductIncompleteFactory = (query) =>
    get(`${url.GET_PRODUCT_INCOMPLETE_FACTORY}`, { params: query }, config);

//dashboardTeams
export const getDashboardTeams = (query) =>
    get(`${url.GET_DASHBOARD_TEAMS}`, { params: query }, config);
export const getDashboardTeamsDetail = (query) =>
    get(`${url.GET_DASHBOARD_TEAMS_DETAIL}`, { params: query }, config);
export const getDashboardTeamsWorking = (query) =>
    get(`${url.GET_DASHBOARD_TEAMS_WORKING}`, { params: query }, config);

//dashboardPlan
export const getDashboardPlan = (query) =>
    get(`${url.GET_DASHBOARD_PLAN}`, { params: query }, config);

//dashboardTechnical
export const getDashboardTechnical = (query) =>
    get(`${url.GET_DASHBOARD_TECHNICAL}`, { params: query }, config);
export const getDashboardTechnicalDetail = (query) =>
    get(`${url.GET_DASHBOARD_TECHNICAL_DETAIL}`, { params: query }, config);

//dashboardQUantity
export const getDashboardQuantity = (query) =>
    get(`${url.GET_DASHBOARD_QUANTITY}`, { params: query }, config);

//product
export const getListStatus = () => get(`${url.GET_LIST_STATUS}`, config);

//ecxel

export const downloadExcel = (data) => post(url.DOWNLOAD_EXCEL, data, config);

export const downloadPlanExcel = (data) =>
    post(url.DOWNLOAD_PLAN_EXCEL, data, config);

export const uploadPlanExcel = (type, formData) =>
    axios.post(
        `${url.UPLOAD_PLAN_EXCEL}-${type}`,
        formData,
        config_upload_file
    );

export const importDataExcel = (data) =>
    post(url.IMPORT_DATA_EXCEL, data, config_upload_file);

export const exportExcel = (data) => post(url.EXPORT_EXCEL, data, config);

//quality
export const getReportQuality = (query) =>
    get(`${url.GET_REPORT_QUALITY}`, { params: query }, config);

//excel factory
export const exportExcelFactory = (data) =>
    post(url.EXPORT_EXCEL_FACTORY, data, config);

//excel Team
export const exportExcelTeam = (data) =>
    post(url.EXPORT_EXCEL_TEAM, data, config);

//excel Building
export const exportExcelBuilding = (data) =>
    post(url.EXPORT_EXCEL_BUILDING, data, config);

export const updateLocation = (id, data) =>
    put(`${url.PRODUCTION}/${id}`, data, config_upload_file);
export const deleteLocation = (id) => del(`${url.PRODUCTION}/${id}`, config);

//getUnit
export const getUnit = () => get(`${url.GET_UNIT}`, config);

//getHistory
export const getHistory = (id) => get(`${url.GET_HISTORY}/${id}`, config);

//getTeam
export const getTeam = () => get(url.GET_TEAM, config);
