import { call, put, takeEvery } from "redux-saga/effects";
import {
    getDashboardTechnicalSuccess,
    getDashboardTechnicalFail,
    getDashboardTechnicalDetailFail,
    getDashboardTechnicalDetailSuccess,
    getAllDashboardTechnicalDetailFail,
    getAllDashboardTechnicalDetailSuccess,
} from "./slice";
import {
    getDashboardTechnical,
    getDashboardTechnicalDetail,
} from "../../api/backend_helper";

function* getDashboardTechnicalSaga(action) {
    try {
        const {
            timeStartReq,
            timeEndReq,
            filterBuildings,
            orderCode,
            category,
            factoryCode,
            filterUnit,
            filterTeamWorking,
        } = action.payload;
        const data = yield call(getDashboardTechnical, {
            timeStartReq,
            timeEndReq,
            filterBuildings,
            orderCode,
            category,
            factoryCode,
            filterUnit,
            filterTeamWorking,
        });
        yield put(getDashboardTechnicalSuccess(data));
    } catch (error) {
        yield put(getDashboardTechnicalFail(error.message));
    }
}

function* getDashboardTechnicalDetailSaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            filterBuildings,
            orderCode,
            category,
            factoryCode,
            status,
            filterStatus,
            filterUnit,
            filterTeamWorking,
        } = action.payload;
        const data = yield call(getDashboardTechnicalDetail, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            filterBuildings,
            orderCode,
            category,
            factoryCode,
            status,
            filterStatus,
            filterUnit,
            filterTeamWorking,
        });
        yield put(getDashboardTechnicalDetailSuccess(data));
    } catch (error) {
        yield put(getDashboardTechnicalDetailFail(error.message));
    }
}

function* getAllDashboardTechnicalDetailSaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            filterBuildings,
            orderCode,
            category,
            factoryCode,
            status,
            filterStatus,
            filterUnit,
            filterTeamWorking,
        } = action.payload;
        const data = yield call(getDashboardTechnicalDetail, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            filterBuildings,
            orderCode,
            category,
            factoryCode,
            status,
            filterStatus,
            filterUnit,
            filterTeamWorking,
        });

        yield put(getAllDashboardTechnicalDetailSuccess(data));
    } catch (error) {
        yield put(getAllDashboardTechnicalDetailFail(error.message));
    }
}

function* DashboardTechnical() {
    yield takeEvery(
        "dashboardTechnical/getDashboardTechnical",
        getDashboardTechnicalSaga
    );
    yield takeEvery(
        "dashboardTechnical/getDashboardTechnicalDetail",
        getDashboardTechnicalDetailSaga
    );
    yield takeEvery(
        "dashboardTechnical/getAllDashboardTechnicalDetail",
        getAllDashboardTechnicalDetailSaga
    );
}

export default DashboardTechnical;
