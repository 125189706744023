import { call, put, takeEvery } from "redux-saga/effects";
import {
    getDashboardQuantityFail,
    getDashboardQuantitySuccess,
    downloadExcelQauntitySuccess,
    downloadExcelQuantityFail,
    getAllDashboardQuantityFail,
    getAllDashboardQuantitySuccess,
} from "./slice";
import { getDashboardQuantity, exportExcel } from "../../api/backend_helper";

function* getDashboardQuantitySaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            filterBuildings,
            orderCode,
            category,
            factoryCode,
            status,
            filterStatus,
            filterUnit,
            filterTeamWorking,
        } = action.payload;
        const data = yield call(getDashboardQuantity, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            filterBuildings,
            orderCode,
            category,
            factoryCode,
            status,
            filterStatus,
            filterUnit,
            filterTeamWorking,
        });
        yield put(getDashboardQuantitySuccess(data));
    } catch (error) {
        yield put(getDashboardQuantityFail(error.message));
    }
}

function* downloadExcelTeamSaga(action) {
    try {
        const data = yield call(exportExcel, action.payload);
        yield put(downloadExcelQauntitySuccess(data));
    } catch (error) {
        yield put(downloadExcelQuantityFail(error));
    }
}

function* getAllDashboardQuantitySaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            filterBuildings,
            orderCode,
            category,
            factoryCode,
            status,
            filterStatus,
            filterUnit,
            filterTeamWorking,
        } = action.payload;
        const data = yield call(getDashboardQuantity, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            filterBuildings,
            orderCode,
            category,
            factoryCode,
            status,
            filterStatus,
            filterUnit,
            filterTeamWorking,
        });
        yield put(getAllDashboardQuantitySuccess(data));
    } catch (error) {
        yield put(getAllDashboardQuantityFail(error.message));
    }
}

function* DashboardQuantity() {
    yield takeEvery(
        "dashboardQuantity/getDashboardQuantity",
        getDashboardQuantitySaga
    );
    yield takeEvery(
        "dashboardQuantity/downloadExcelQuantity",
        downloadExcelTeamSaga
    );
    yield takeEvery(
        "dashboardQuantity/getAllDashboardQuantity",
        getAllDashboardQuantitySaga
    );
}

export default DashboardQuantity;
